import { useContext, useEffect, useState } from "react";
import { useRouter } from "next/router";
import Image from "next/image";
import dynamic from "next/dynamic";
import Link from "next/link";
import SearchOverlay from "./SearchOverlay";
import Utils from "../../utils/Utils";

import { destroyCookie } from "nookies";
import { BsArrowLeft } from "react-icons/ai";

import Nav from "react-bootstrap/Nav";

import Navbar from "react-bootstrap/Navbar";

const NavDrop = dynamic(() => import("./NavDrop/NavDrop"), { ssr: true });

import Container from "react-bootstrap/Container";
import logo from "../../assets/images/shotqualitybetsLogo.png";
import logoIcon from "../../assets/images/ball-bask.png";
import { AppContext } from "../../context/state";

function useQuery() {
  return new URLSearchParams(useRouter());
}

const jbl_links = {
  league: "JBL",
  links: [
    { name: "JBL ScoreCenter", to: `/japan_b1/shotquality-scores` },
    { name: "Trader Dashboard", to: `/trader-dashboard` },
    { name: "Live Query Tool (Coming soon)", to: `#` },
  ],
};

const menuLinks = [
  // {
  //   league: "March Madness",
  //   links: [
  //     { name: "March Madness", to: `/marchmadness` },
  //     { name: "March Madness Live", to: `/ncaa/shotquality-scores` },
  //     { name: "Bracket Builder", to: `/march-madness-tool` },
  //     { name: "Value Bracket", to: `/value-bracket` },
  //     { name: "March Madness Matchups", to: `/march-madness-matchups` },
  //     { name: "March Madness Primer", to: `/march-madness-primer` },
  //   ],
  //   className: ["hide-desktop"],
  // },
  {
    league: "NBA",
    links: [
      { name: "ScoreCenter", to: `/nba/shotquality-scores` },
      // { name: "Daily Projections", to: `/nba/linevalue` },
      // { name: "Best Bets", to: `/nba/best-bets` },
      // { name: "Results", to: `/nba/model-results` },
      // { name: "Betting Analysis", to: `/nba/betting-results` },
      { name: "Betting Trends", to: `/nba/betting-record` },
      { name: "Team Standings", to: `/nba/team-standings` },
      { name: "Regression Stats", to: `/nba/regression-stats` },
      { name: "Trend Finder", to: `/nba/trend-finder` },
      { name: "Matchup Simulator", to: `/nba/matchup-tool` },
      { name: "Player Analysis", to: `/nba/player-stats` },
      { name: "Player Props Research", to: `/nba/picks/playerprops` },
      // { name: "Custom Model Sandbox", to: `/model-sandbox` },
      // { name: "Premium Tools", to: "/premium-tools" },
    ],
    className: ["hide-desktop"],
  },
  {
    league: "WNBA",
    links: [
      { name: "ScoreCenter", to: `/wnba/shotquality-scores` },
      // { name: "Daily Projections", to: `/wnba/linevalue` },
      // { name: "Best Bets", to: `/wnba/best-bets` },
      // { name: "Results", to: `/wnba/model-results` },
      // { name: "Betting Analysis", to: `/wnba/betting-results` },
      { name: "Betting Trends", to: `/wnba/betting-record` },
      { name: "Team Standings", to: `/wnba/team-standings` },
      { name: "Regression Stats", to: `/wnba/regression-stats` },
      { name: "Trend Finder", to: `/wnba/trend-finder` },
      { name: "Matchup Simulator", to: `/wnba/matchup-tool` },
      { name: "Player Analysis", to: `/wnba/player-stats` },
      { name: "Player Props Research", to: `/wnba/picks/playerprops` },
      // { name: "Premium Tools", to: "/premium-tools" },
    ],
    className: ["hide-desktop"],
  },
  {
    league: "NCAAM",
    links: [
      { name: "ScoreCenter", to: `/ncaa/shotquality-scores` },
      // { name: "Daily Projections", to: `/ncaa/linevalue` },
      // { name: "Best Bets", to: `/ncaa/best-bets` },
      // { name: "Results", to: `/ncaa/model-results` },
      // { name: "Betting Analysis", to: `/ncaa/betting-results` },
      { name: "Betting Trends", to: `/ncaa/betting-record` },
      { name: "Team Standings", to: `/ncaa/team-standings` },
      { name: "Regression Stats", to: `/ncaa/regression-stats` },
      { name: "Trend Finder", to: `/ncaa/trend-finder` },
      { name: "Matchup Simulator", to: `/ncaa/matchup-tool` },
      // { name: "Custom Model Sandbox", to: `/model-sandbox` },
      { name: "Player Analysis", to: `/ncaa/player-stats` },
      { name: "Picks and Predictions", to: `/ncaa/picks` },
      // { name: "Premium Tools", to: "/premium-tools" },

      // { name: "SQ Wire", to: "/blog" },
      // { name: 'March Madness', to: '/march-madness-tool' }
    ],
    className: ["hide-desktop"],
  },
  {
    league: "NCAAW",
    links: [
      { name: "ScoreCenter", to: `/ncaaw/shotquality-scores` },
      { name: "Team Standings", to: `/ncaaw/team-standings` },
      { name: "Regression Stats", to: `/ncaaw/regression-stats` },
      { name: "Player Analysis", to: `/ncaaw/player-stats` },
    ],
    className: ["hide-desktop"],
  },
  {
    league: "Analysis",
    links: [
      { name: "ShotQuality Wire", to: `/blog` },
      { name: "BetCast", to: `/betcast` },
    ],
  },
];

export const Header = () => {
  const user = useContext(AppContext)?.userDetail;
  // const user = localStorage.getItem("user") ? true : false;
  const router = useRouter();
  let query = useQuery(router.query);
  const [showSQButton, toggleShowSQButton] = useState(false);
  const isTrader = Utils.isTrader(user);
  const [isSearchOpen, setIsSearchOpen] = useState(false);

  const openSearch = () => {
    setIsSearchOpen(true);
  };

  const closeSearch = () => {
    setIsSearchOpen(false);
  };
  function deleteCookie(name) {
    document.cookie = name + "=; expires=Thu, 23 Jul 1998 00:00:00 UTC; path=/;";
  }

  // Function to clear all cookies
  function clearAllCookies() {
    var cookies = document.cookie.split(";");

    for (var i = 0; i < cookies.length; i++) {
      var cookie = cookies[i];
      var eqPos = cookie.indexOf("=");
      var name = eqPos > -1 ? cookie.substring(0, eqPos) : cookie;
      deleteCookie(name);
    }
  }
  const handleLogout = (e) => {
    e.preventDefault();
    localStorage.clear();
    destroyCookie({}, "name");
    destroyCookie({}, "access_token");
    destroyCookie({}, "token_access");
    clearAllCookies();
    const a = document.createElement("a");
    a.href = "/login#login";
    a.click();
    // router.push("/login#login");
  };
  // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
  useEffect(() => {
    if (query.get("referrer") === "shotquality") {
      toggleShowSQButton(true);
    }
  }, []);

  const handleClick = () => {
    if (typeof window !== "undefined") {
      localStorage.setItem("preLoginUrl", window.location.pathname);
    }
  };
  const emailDomain = user?.email.split("@")[1].split(".")[0];
  let traderMenuLinks = [
    {
      league: "WNBA",
      links: [
        { name: "ScoreCenter", to: `/wnba/shotquality-scores?type=trader&org=${emailDomain}` },
        { name: "Trader Dashboard", to: `/trader-dashboard?type=trader&org=${emailDomain}` },
        { name: "Live Query Tool", to: `/wnba/live-query-tool?type=trader&org=${emailDomain}` },
        { name: "Regression Stats", to: `/wnba/regression-stats?type=trader&org=${emailDomain}` },
        { name: "Trend Finder", to: `/wnba/trend-finder?type=trader&org=${emailDomain}` },
        { name: "Player Props Research", to: `/wnba/picks/playerprops?type=trader&org=${emailDomain}` },
        // { name: "Player Stats", to: `/wnba/player-stats?type=trader&org=${emailDomain}` },
        { name: "Team Standings", to: `/wnba/team-standings?type=trader&org=${emailDomain}` },
        { name: "Matchup Simulator", to: `/wnba/matchup-tool?type=trader&org=${emailDomain}` },
        { name: "API", to: `/build?type=trader&org=${emailDomain}` },
      ],
    },
    {
      league: "NBA",
      links: [
        { name: "ScoreCenter", to: `/nba/shotquality-scores?type=trader&org=${emailDomain}` },
        { name: "Trader Dashboard", to: `/trader-dashboard?type=trader&org=${emailDomain}` },
        { name: "Live Query Tool", to: `/nba/live-query-tool?type=trader&org=${emailDomain}` },
        { name: "Regression Stats", to: `/nba/regression-stats?type=trader&org=${emailDomain}` },
        { name: "Trend Finder", to: `/nba/trend-finder?type=trader&org=${emailDomain}` },
        // { name: "Player Stats", to: `/nba/player-stats?type=trader&org=${emailDomain}` },
        { name: "Player Props Research", to: `/nba/picks/playerprops?type=trader&org=${emailDomain}` },
        { name: "Team Standings", to: `/nba/team-standings?type=trader&org=${emailDomain}` },
        { name: "Matchup Simulator", to: `/nba/matchup-tool?type=trader&org=${emailDomain}` },
        { name: "API", to: `/build?type=trader&org=${emailDomain}` },
      ],
    },
    {
      league: "NCAA",
      links: [
        { name: "ScoreCenter", to: `/ncaa/shotquality-scores?type=trader&org=${emailDomain}` },
        { name: "Trader Dashboard", to: `/trader-dashboard?type=trader&org=${emailDomain}` },
        { name: "Live Query Tool", to: `/ncaa/live-query-tool?type=trader&org=${emailDomain}` },
        { name: "Regression Stats", to: `/ncaa/regression-stats?type=trader&org=${emailDomain}` },
        { name: "Trend Finder", to: `/ncaa/trend-finder?type=trader&org=${emailDomain}` },
        // { name: "Player Stats", to: `/ncaa/player-stats?type=trader&org=${emailDomain}` },
        { name: "Team Standings", to: `/ncaa/team-standings?type=trader&org=${emailDomain}` },
        { name: "Matchup Simulator", to: `/ncaa/matchup-tool?type=trader&org=${emailDomain}` },
        { name: "API", to: `/build?type=trader&org=${emailDomain}` },
      ],
    },

    // {
    //   league: "NCAAW",
    //   links: [
    //     { name: "ScoreCenter", to: `/ncaaw/shotquality-scores?type=trader&org=${emailDomain}` },
    //     { name: "Regression Stats", to: `/ncaaw/regression-stats?type=trader&org=${emailDomain}` },
    //     // { name: "Matchup Tool", to: `/ncaaw/matchup-tool?type=trader&org=${emailDomain}` },
    //     { name: "Trend Finder", to: `/ncaaw/trend-finder?type=trader&org=${emailDomain}` },
    //     // { name: "Player Stats", to: `/ncaaw/player-stats?type=trader&org=${emailDomain}` },
    //     { name: "Team Standings", to: `/ncaaw/team-standings?type=trader&org=${emailDomain}` },
    //     { name: "API", to: `/build?type=trader&org=${emailDomain}` },
    //   ],
    // },
    // {
    //   league: "March Madness",
    //   links: [
    //     { name: "March Madness", to: `/marchmadness?type=trader&org=${emailDomain}` },
    //     { name: "Bracket Builder", to: `/march-madness-tool?type=trader&org=${emailDomain}` },
    //     { name: "Value Bracket", to: `/value-bracket?type=trader&org=${emailDomain}` },
    //     { name: "March Madness Matchups", to: `/march-madness-matchups?type=trader&org=${emailDomain}` },
    //     // { name: "Premium Analysis", to: `/premium-tools?type=trader&org=${emailDomain}` },
    //   ],
    // },
  ];

  // only show JBL links if user has JBL account
  // const jblExists = traderMenuLinks.find((menu) => menu.league === "JBL");
  // if (!jblExists && isTrader && user?.accounts?.length > 0) {
  //   for (const account of user.accounts) {
  //     if (account.account_type.league === "jbl") {
  //       traderMenuLinks.push(jbl_links);
  //     }
  //   }
  // }

  return (
    <Navbar
      expand="lg"
      sticky="top"
      className="bg-body-tertiary border-body sqbets-nav"
      bg="light"
      data-bs-theme="light"
    >
      <Container fluid style={{ gap: "1rem" }}>
        <div className="navbar-logo-and-menu">
          <Navbar.Toggle aria-controls="responsive-navbar-nav" style={{ marginRight: "1rem" }} />
          <Navbar.Brand href="/" style={{ maxWidth: "15.625rem", position: "relative" }}>
            <Image
              className="logo main"
              src={logo.src}
              width={250}
              height={19}
              style={{ height: "auto" }}
              loading="lazy"
              alt="Shotquality Brand image"
            />
            <Image
              className="icon logo"
              src={logoIcon.src}
              width={25}
              height={25}
              style={{ height: "auto" }}
              loading="lazy"
              alt="Shotquality Brand image"
            />
          </Navbar.Brand>
        </div>

        <span className="d-md-block d-lg-none d-none">
          <Nav style={{ display: "flex", flexDirection: "row", gap: "1rem" }}>
            <button className={"is-link is-link pl-0 text-black"} onClick={openSearch}>
              <i className="fa fa-search"></i> <strong>Search</strong>
            </button>
            {user ? (
              <>
                {showSQButton ? (
                  <div className="d-flex col-sm-4 text-left links">
                    <a href="https://shotquality.com">
                      <button className="mr-3 btn btn-primary">
                        <BsArrowLeft /> Back to ShotQuality.com
                      </button>
                    </a>
                  </div>
                ) : null}
                <Nav.Link href="/profile-settings" className="menu-title-strong" style={{ marginRight: "1rem" }}>
                  My Account
                </Nav.Link>
                <a href="#" className="min-width-100px btn btn-orange" onClick={handleLogout}>
                  Logout
                </a>
              </>
            ) : (
              <>
                <Nav.Link href="/login#login" className="menu-title-strong" onClick={Utils.setPreLoginUrl}>
                  Log In
                </Nav.Link>
                <Link href="/welcome" className="min-width-100px btn btn-green" onClick={Utils.setPreLoginUrl}>
                  Sign up
                </Link>
              </>
            )}
          </Nav>
        </span>

        <Navbar.Collapse
          id="responsive-navbar-nav"
          className="justify-content-between dropdown-toggle"
          data-bs-toggle="dropdown"
        >
          <Nav className="me-auto">
            {isTrader
              ? traderMenuLinks.map((menu) => <NavDrop key={menu.league} menu={menu} />)
              : menuLinks.map((menu) => <NavDrop key={menu.league} menu={menu} />)}

            {/* Dont show basketball analysis for trader account */}
            {/* {!isTrader && (
              <Nav.Link href="/blog" className="menu-title-strong" style={{ marginRight: "1rem" }}>
                Basketball Analysis
              </Nav.Link>
            )} */}
            {/* <Nav.Link href="/march-madness-tool" className="menu-title-strong" style={{ marginRight: "1rem" }}>
                March Madness
              </Nav.Link> */}
            {isTrader && (
              <Nav.Link href="/stats-explained" className="menu-title-strong" style={{ marginRight: "1rem" }}>
                Stats Explained
              </Nav.Link>
            )}
            <button className={"text-left d-md-none d-block d-lg-block is-link pl-0 text-black"} onClick={openSearch}>
              <i className="fa fa-search"></i> <strong>Search</strong>
            </button>
          </Nav>

          <Nav className="d-block d-lg-flex d-md-none">
            {user ? (
              <>
                <Nav.Link href="/profile-settings" className="menu-title-strong" style={{ marginRight: "1rem" }}>
                  My Account
                </Nav.Link>
                <a href="#" className="min-width-100px btn btn-orange" onClick={handleLogout}>
                  Logout
                </a>
              </>
            ) : (
              <>
                {showSQButton ? (
                  <div className="d-flex col-sm-4 text-left links">
                    <a href="https://shotquality.com">
                      <button className="mr-3 btn btn-primary">
                        <BsArrowLeft /> Back to ShotQuality.com
                      </button>
                    </a>
                  </div>
                ) : null}

                <Nav.Link href="/login#login" className="menu-title-strong" onClick={Utils.setPreLoginUrl}>
                  Log In
                </Nav.Link>
                <Link href="/welcome" className="min-width-100px btn btn-green" onClick={Utils.setPreLoginUrl}>
                  Sign up
                </Link>
              </>
            )}
          </Nav>
        </Navbar.Collapse>
        <SearchOverlay isOpen={isSearchOpen} onClose={closeSearch} />
      </Container>
    </Navbar>
  );
};

export const leagueData = {
  college_mens: {
    label: "COLLEGE MENS",
    icon: Utils.getAsset("ncaa_mens_icon.png"),
  },
  nba: {
    label: "NBA",
    icon: Utils.getAsset("nba_icon.jpg"),
  },
  wnba: {
    label: "WNBA",
    icon: Utils.getAsset("wnba_icon.png"),
  },
};
